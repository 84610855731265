<app-external-header></app-external-header>
<section class="page-section about-page scrolled-offset" id="about">
  <div class="container">
      <div class="text-center">
          <h2 class="section-heading text-uppercase">Who is HonorVet Technologies</h2>
          <h3 class="section-subheading text-muted">Serving the ones who servedSM</h3>
      </div>
      <ul class="timeline">
          <li>
              <div class="timeline-image"><span class="rounded-circle img-fluid"> 1 </span></div> 
              <div class="timeline-panel">
                  <div class="timeline-heading" hidden>
                      <h4>2009-2011</h4>
                      <h4 class="subheading">Lorem ipsum dolor sit amet,</h4>
                  </div>
                  <div class="timeline-body"><p class="text-muted">HonorVet Technologies is a technology-driven company that provides the reach of a large staffing organization with the feel of a specialized, boutique provider. Our purpose is to lower the underemployment in the Veteran community by providing them with best-fit opportunities in civilian workplaces. We are disrupting Talent Acquisition with advanced recruitment solutions, a unique combination of Predictive Analysis, Artificial Intelligence, Processes, and Methodologies to support the company’s “High Touch” Expertise. HonorVet Technologies deliver ideal recruitment solutions to our clients with suitable skillsets (preferably veterans, along with non-veterans, for the hard-to-fill positions).</p></div>
              </div>
          </li>
          <li class="timeline-inverted">
              <div class="timeline-image"><span class="rounded-circle img-fluid"> 2 </span></div>
              <div class="timeline-panel">
                  <div class="timeline-heading" hidden>
                      <h4>March 2011</h4>
                      <h4 class="subheading">Lorem ipsum dolor</h4>
                  </div>
                  <div class="timeline-body"><p class="text-muted">In addition to our sophisticated platforms, our identification and screening processes assure our clients can successfully achieve their talent search and placement initiatives faster than traditional mainstream methods. Our experienced staff operates under a principle of professionalism that demands excellence. We are committed to providing businesses with innovative solutions enabling more efficiency and technological advancements.</p></div>
              </div>
          </li>
          <li>
              <div class="timeline-image"><span class="rounded-circle img-fluid"> 3 </span></div>
              <div class="timeline-panel">
                  <div class="timeline-heading" hidden>
                      <h4>December 2015</h4>
                      <h4 class="subheading">Lorem ipsum dolor sit amet, </h4>
                  </div>
                  <div class="timeline-body"><p class="text-muted">Furthermore, our status as a Service-Disabled Veteran Owned Small Business (SDVOSB) allow our clients to meet their diversity initiatives or objectives. HonorVet Technologies provides clients with optimal placements by combining human interactions and experience with data-driven findings about candidates.</p></div>
              </div>
          </li> 
          <li class="timeline-inverted">
              <div class="timeline-image">
                  <h4>
                      Be Part
                      <br>
                      Of Our
                      <br>
                      Story!
                  </h4>
              </div>
          </li>
      </ul>
  </div>
</section>
<!-- End -->
<app-footer></app-footer>