<app-external-header></app-external-header>
 <!-- Main Page Start -->
 <div class="wrapper employers">
  <section class="banner">
      <div class="container">
        <div class="bannerContent">
          <!-- 1 -->
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="content">
              <span class="top-cta">Looking for a employer</span>
              <h2 class="mainheading">For employers who need great people</h2>
              <p class="para2">Reach out to millions of jobseekers and hire quickly with our spoton candidate recommendations.</p>
              <a class="orangeBtn pd" href="javascript:void(0)" routerLink="/create-account">Register now</a>    
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="img bk">  
              <img src="assets/images/homepage/employers.png" alt="banner">       
            </div>
            </div>             
          </div>
      </div> 
    </div> 
  </section>
<!-- End -->
<section class="feature pd-tb">
<div class="container">
<h3 class="subheading">Bouquet of features to meet all your hiring needs</h3>
<div class="row">
   <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
      <div class="feature-content">
        <img src="assets/images/icons/job-posting.svg" alt="Job Posting">
        <h4 class="small-heading">Job posting</h4>
        <p class="para mt-2">Reach your next hire faster. Get relevant responses to your job ads with our new age technology.</p>
      </div>
    </div> 
    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
      <div class="feature-content">
        <img src="assets/images/icons/chat.svg" alt="Chat">
        <h4 class="small-heading">Chat with candidates</h4>
        <p class="para mt-2">Quickly connect with jobseekers through chat and get more responses.</p>
      </div>
    </div> 
    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
      <div class="feature-content">
        <img src="assets/images/icons/unlimited.svg" alt="Unlimited">
        <h4 class="small-heading">Unlimited job posts</h4>
        <p class="para mt-2">Lorem ipsum dolor sit amet consetetur sadipscing elitr sed diam nonu.</p>
      </div>
    </div> 
</div>
</div>  
</section>
<!-- End -->
<section class="feature steps pd-tb">
<div class="container">
<h3 class="subheading">Steps to start posting jobs</h3>
<div class="row">

  <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
    <div class="feature-content">
      <div class="number">01</div>
      <img src="assets/images/icons/freeaccount.svg" alt="Free Account">
      <h4 class="small-heading">Create your free account</h4>
      <p class="para mt-2">No need to pay heavy subscriptions fees. Join us for free and start posting your jobs.</p>
    </div>
  </div>

       <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
       <div class="feature-content">
        <div class="number">02</div>
         <img src="assets/images/icons/create.svg" alt="Create Account">
         <h4 class="small-heading">Create your job post</h4>
         <p class="para mt-2">Quickly and easily create your job post and get ready for spoton recommendations.</p>
       </div>
     </div>    

     <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
      <div class="feature-content">
        <div class="number">03</div>
        <img src="assets/images/icons/publish.svg" alt="Publish">
        <h4 class="small-heading">Publish your job</h4>
        <p class="para mt-2">Post your jobs and chat with job seekers to have 360 picture.</p>
      </div>
    </div> 
</div>
</div>  
</section>
<!-- end -->
<section class="  half-color-box success-stories">
<div class= "container spacer por">
<div class="title">
  <h4 class="orange">Success stories</h4>
  <h3 class="subheading">Veterans recommend honorvet</h3>
  <p class="para">Honorvet is used as the main tool for talent acquistion by lacs of recruiters across all kinds and sizes of industry. They appreciate Honorvet’s services and highly recommend it as the non-stop solution to all the hiring needs of a company.</p>
</div>


<div class="test-arrowbox">

  <div class=" button prev ">
    <img src="assets/images/icons/left_arrow.svg">
  </div>
  <div class=" button next ">
      <div class="arrow-right">
        <img src="assets/images/icons/right_arrow.svg">
      </div>

  </div>
</div> 

<owl-carousel-o [options]="customOptions">
  <ng-container *ngFor="let data of allData; let i=index;">
    <ng-template carouselSlide class="slide" [id]="data.id">
      <div class="client-content">
        <div class="clientimg">
          <img [src]="globalUrl + data.uploadImage" [alt]="data.name" [title]="data.name">
        </div>
        <h6>{{data.name}}</h6>
        <p class="job_detail">{{data.designation}}  at {{data.companyName}}</p>
        <p class="para">{{data.description}}</p>
      </div>
    </ng-template>
  </ng-container> 
</owl-carousel-o> 
<div class="noDataFound" *ngIf="allData.length == 0">
  <h2>No Records Found</h2>
</div>
</div>
</section>



</div> 

<!-- End -->
<app-footer></app-footer>