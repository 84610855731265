import { Component, OnInit } from "@angular/core";
import { OwlOptions } from "ngx-owl-carousel-o";
import { AdminService } from "../../services/admin.service";
import { apiUrl } from "../../global/global";
import { ImageUrls } from "src/app/services/s3buket";

@Component({
  selector: "app-employers",
  templateUrl: "./employers.component.html",
  styleUrls: ["./employers.component.css"]
})
export class EmployersComponent implements OnInit {
  allData:any=[];
  globalUrl = ImageUrls.BaseUrl + ImageUrls.directories.admin;
  customOptions: OwlOptions = {
      loop: false,
      mouseDrag: false,
      touchDrag: false,
      pullDrag: false,
      dots: true,
      navSpeed: 400,
      nav: true,
      navText: ["prev", "next"],
      center: false,
      responsive: {
        0: {
          items: 1,
        },
        600: {
          items: 2,
        },
        1000: {
          items: 3,
        },
      },
    }; 
  constructor(private api: AdminService,) { }

  ngOnInit(): void {
    this.getEmployer();
  }
  // get state
  getEmployer(){
    this.api.getDatas(apiUrl._employerHome)
    .subscribe(async (res) => {
      this.allData = res.data.data;
    })
  }

}
