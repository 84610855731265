<app-external-header></app-external-header>

<section class="page-section  scrolled-offset" id="about">
  <div class="container">
    <div class="title-bar">
      <h1 class="subheading">FAQs for Recruiters:</h1>
    </div>
    
    <div class="accordion-wrapper">
      <ngb-accordion [destroyOnHide]='false' [closeOthers]="true" activeIds="panel1">

        <ngb-panel id="panel1">
          <ng-template ngbPanelTitle>
              <h3>How can I post job opportunities on HonorVet? </h3>
          </ng-template>
          <ng-template ngbPanelContent>
            <p>HonorVet offers a user-friendly platform for recruiters to post job opportunities dedicated to military veterans and their families. Simply sign up as a recruiter, create an account, and follow the guided steps to post your job openings.</p>
          </ng-template>
        </ngb-panel>

        <ngb-panel id="panel2">
          <ng-template ngbPanelTitle>
              <h3>What are the benefits of using HonorVet for recruiting veterans?</h3>
          </ng-template>
          <ng-template ngbPanelContent>
          <p> By using HonorVet, you gain access to a talent pool of highly skilled and motivated military veterans and their families. Veterans bring valuable qualities such as leadership, adaptability, and teamwork to the workforce, making them valuable assets to any organization. </p>
          </ng-template>
        </ngb-panel>

        <ngb-panel id="panel3">
          <ng-template ngbPanelTitle>
              <h3>Can I search for candidates with specific experience or skills?</h3>
          </ng-template>
          <ng-template ngbPanelContent>
            <p>Yes, HonorVet allows you to refine your candidate search based on specific experiences, skills, and qualifications. This targeted approach helps you find candidates who align perfectly with your job requirements.</p>
          </ng-template>
        </ngb-panel>
        <ngb-panel id="panel4">
          <ng-template ngbPanelTitle>
              <h3>What types of companies and organizations can use HonorVet for recruiting?
              </h3>
          </ng-template>
          <ng-template ngbPanelContent>
          <p> HonorVet is open to all types of companies, from startups to large corporations, as well as government agencies and non-profit organizations. If you're committed to hiring veterans and their families, HonorVet is the right platform for you.</p>
          </ng-template>
        </ngb-panel> 
      </ngb-accordion>  
    </div>
  </div>
</section>
<!-- End -->
<app-footer></app-footer>