<app-external-header></app-external-header>
<!-- Main Page Start -->
<div class="wrapper employers jobseekers">
  <section class="banner">
      <div class="container">
        <div class="bannerContent">
          <!-- 1 -->
          <div class=" row">
            <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="content">
              <span class="top-cta">Looking for a job</span>
              <h2 class="mainheading">Discover relevant jobs across popular roles and top companies</h2>
              <p class="para2">Our leading edge jobs recommendation engine will help you find the job that best matches your profile and needs.</p>
              <a class="orangeBtn pd" (click)="open(template)">Register now</a>    
            </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="img bk">  
              <img src="assets/images/homepage/discover-relevant-job.png" alt="Relevant">       
            </div>
          </div>            
          </div>
      </div> 
    </div> 
  </section>
<!-- End -->
<section class="feature board-range pd-tb">
<div class="container">
<div class="row">
   <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
      <div class="feature-content">
        <img src="assets/images/icons/range.svg" alt="range">
        <h4 class="small-heading">Broad range of jobs for veterans</h4>
       
      </div>
    </div> 
    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
      <div class="feature-content">
        <img src="assets/images/icons/family.svg" alt="Family">
        <h4 class="small-heading">Jobs for veteran’s family members</h4>
        
      </div>
    </div> 
    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
      <div class="feature-content">
        <img src="assets/images/icons/connect.svg" alt="Connect">
        <h4 class="small-heading">Instantly connect with top recruiters</h4>
        
      </div>
    </div> 
</div>
</div>  
</section>

<!-- Jobs -->
<section class="pd-tb ">
<div class="container">
<div class="employer resume"> 
 <div class="employerContent ">
         <h5  class="orange">It’s easy and simple</h5>
         <h3 class="second-heading">Build your resume</h3>
         <p class="para2">We are a certified service disabled veteran owned small business (SDVOSB), through the US department of veterans affairs and by the national veteran business development council (NVBDC).</p>
         <a class="orangeBtn pd" routerLink="/login">Build now</a>
  </div>
</div>

</div> 
</section>
<!-- End -->
<section class="feature steps pd-tb">
<div class="container">
<h3 class="subheading">Steps to get Job</h3>
<div class="row">

  <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
    <div class="feature-content">
      <div class="number">01</div>
      <img src="assets/images/icons/freeaccount.svg" alt="Free Account">
      <h4 class="small-heading">Create your free account</h4>
      <p class="para mt-2">Create your profile or resume at no cost.</p>
    </div>
  </div>

       <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
       <div class="feature-content">
        <div class="number">02</div>
         <img src="assets/images/icons/publish-1.svg" alt="Publish">
         <h4 class="small-heading">Publish your profile</h4>
         <p class="para mt-2">Review your profile and publish it.</p>
       </div>
     </div>    

     <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
      <div class="feature-content">
        <div class="number">03</div>
        <img src="assets/images/icons/apply.svg" alt="apply">
        <h4 class="small-heading">Quickly apply to a job</h4>
        <p class="para mt-2">Search jobs and easily apply to instantly connect with recruiter.</p>
      </div>
    </div> 
</div>
</div>  
</section>


<!-- end -->
<section class="  half-color-box success-stories">
<div class="container spacer por">
<div class="title">
  <h4 class="orange">Success stories</h4>
  <h3 class="subheading">Veterans recommend honorvet</h3>
  <p class="para">Honorvet is used as the main tool for talent acquistion by lacs of recruiters across all kinds and sizes of industry. They appreciate Honorvet’s services and highly recommend it as the non-stop solution to all the hiring needs of a company.</p>
</div>

<div class="test-arrowbox">

  <div class=" button prev ">
    <img src="assets/images/icons/left_arrow.svg">
  </div>
  <div class=" button next ">
      <div class="arrow-right">
        <img src="assets/images/icons/right_arrow.svg">
      </div>

  </div>
</div> 

<owl-carousel-o [options]="customOptions">
  <ng-container *ngFor="let data of allData; let i=index;">
    <ng-template carouselSlide class="slide" [id]="data.id">
      <div class="client-content">
        <div class="clientimg">
          <img [src]="globalUrl + data.uploadImage" [alt]="data.name" [title]="data.name">
        </div>
        <h6>{{data.name}}</h6>
        <p class="job_detail">{{data.designation}}  at {{data.companyName}}</p>
        <p class="para">{{data.description}}</p>
      </div>
    </ng-template>
  </ng-container> 
</owl-carousel-o> 
<div class="noDataFound" *ngIf="allData.length == 0">
  <h2>No Records Found</h2>
</div>

</div>

</section>



</div> <!--End main div--> 

<!-- End -->
<app-footer></app-footer>

<!-- End -->
<ng-template #template>
  <div class="modal-body  text-center  ">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="this.modalRef?.hide()"><span aria-hidden="true">&times;</span></button>
    <h5 class="modal-title"> How will you best describe yourself? </h5>
    <p> Let us know you better to serve you better. </p>
    <form [formGroup]="registerForm">
      <ul class="selectUser">
        <li>
            <input type="radio" name="userType" id="radio1" class="selectUser_radio" checked="" formControlName="userType" value="0"/>
            <label for="radio1" class="selectUser_label"><img src="assets/images/ic_veteran.svg" /> <span> I am a veteran </span> </label>
        </li>
        <li>
            <input type="radio" name="userType" id="radio2" class="selectUser_radio" value="1" formControlName="userType"/>
            <label for="radio2" class="selectUser_label" ><img src="assets/images/ic_veteran_family.png" /> <span> I am a veteran’s family member </span> </label>
        </li>
      </ul>
      <div class="text-danger" *ngIf="registerForm.controls['userType'].hasError('required') && showError">
        *Please select on option.
      </div>
      <input type="submit" class="commonBtn" value="Continue" name="" (click)="signUp()">

      <!-- <a href="javascript:void(0)" routerLink="/choose-yourself" class="commonBtn" (click)="this.modalRef?.hide()"> Continue </a> -->
    </form>
  </div>
</ng-template>